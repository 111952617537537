<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<b-card
			v-show="refsLoaded && showCard('displayed-date-bran')"
			ref="displayed-date-bran"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.displayed-date-bran
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('displayed_date_bran_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="displayed-date-bran"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('displayed_date_bran_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>
		<b-card
			v-show="refsLoaded && showCard('shipping-services')"
			ref="shipping-services"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.shipping-services
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('shipping_services_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="shipping-services"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('shipping_services_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('products-outside')"
			ref="products-outside"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.products-outside
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('products_outside_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="products-outside"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text v-html="translate('products_outside_answer')" />
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('purchase-outside')"
			ref="purchase-outside"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.purchase-outside
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('purchase_outside_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="purchase-outside"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text v-html="translate('purchase_outside_answer')" />
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('return-policy')"
			ref="return-policy"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.return-policy
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('return_policy_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="return-policy"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text v-html="translate('return_policy_answer')" />
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('not-in-faq')"
			ref="not-in-faq"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.not-in-faq
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('not_in_faq_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="not-in-faq"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('not_in_faq_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('v-fill')"
			ref="v-fill"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.v-fill
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('v_fill_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="v-fill"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('v_fill_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('v-fill-processed')"
			ref="v-fill-processed"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.v-fill-processed
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('v_fill_processed_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="v-fill-processed"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('v_fill_processed_answer_1') }} <br><br>
						{{ translate('v_fill_processed_answer_2') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('v-fill-not-processed')"
			ref="v-fill-not-processed"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.v-fill-not-processed
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('v_fill_not_processed_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="v-fill-not-processed"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('v_fill_not_processed_answer') }}
						<ul>
							<li>{{ translate('v_fill_not_processed_answer_1') }}</li>
							<li>{{ translate('v_fill_not_processed_answer_2') }}</li>
							<li>{{ translate('v_fill_not_processed_answer_3') }}</li>
						</ul>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('when-get-products')"
			ref="when-get-products"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.when-get-products
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('when_get_products_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="when-get-products"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('when_get_products_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-if="!customerRoles.includes($user.details().type)"
			v-show="refsLoaded && showCard('bo-power-leg')"
			ref="bo-power-leg"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-power-leg
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('power_leg_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-power-leg"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('power_leg_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bo-auth-charge')"
			ref="bo-auth-charge"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-auth-charge
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('authorization_charge_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-auth-charge"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('authorization_charge_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bo-see-customers')"
			ref="bo-see-customers"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-see-customers
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('see_customers_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-see-customers"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('see_customers_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bo-member-resp')"
			ref="bo-member-resp"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-member-resp
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('member_responsibilities_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-member-resp"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('member_responsibilities_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bo-enroller-resp')"
			ref="bo-enroller-resp"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-enroller-resp
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('enroller_responsibilities_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-enroller-resp"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('enroller_responsibilities_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bo-trademark')"
			ref="bo-trademark"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-trademark
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('trademark_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-trademark"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text v-html="translate('trademark_answer')" />
				</b-card-body>
			</b-collapse>
		</b-card>

		<!-- <b-card
			v-if="$user.details().type === preDistributor || admin.includes($user.details().type)"
			v-show="refsLoaded && showCard('bo-prod-prelaunch')"
			ref="bo-prod-prelaunch"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-prod-prelaunch
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('products_prelaunch_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-prod-prelaunch"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('products_prelaunch_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card> -->

		<!-- <b-card
			v-if="$user.details().type === preDistributor || admin.includes($user.details().type)"
			v-show="refsLoaded && showCard('prod-prelaunch-volume')"
			ref="prod-prelaunch-volume"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.prod-prelaunch-volume
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('products_prelaunch_volume_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="prod-prelaunch-volume"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('products_prelaunch_volume_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card> -->

		<!-- <b-card
			v-if="$user.details().type === preDistributor || admin.includes($user.details().type)"
			v-show="refsLoaded && showCard('bo-acquiring-prod')"
			ref="bo-acquiring-prod"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-acquiring-prod
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('benefits_acquiring_products_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-acquiring-prod"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('benefits_acquiring_products_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card> -->

		<!-- <b-card
			v-if="$user.details().type === preDistributor || admin.includes($user.details().type)"
			v-show="refsLoaded && showCard('bo-keep-registering')"
			ref="bo-keep-registering"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-keep-registering
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('keep_registering_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-keep-registering"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('keep_registering_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card> -->
	</div>
</template>

<script>
import {
	customerRoles,
	preDistributor,
	admin,
} from '@/settings/Roles';
import {
	Faq as messages,
} from '@/translations';
import SearchMixin from './mixins/Search';

export default {
	name: 'FaqGeneralInformation',
	messages,
	mixins: [SearchMixin],
	props: {
		searchTerm: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			customerRoles,
			preDistributor,
			admin,
		};
	},
	methods: {
		showCard(ref) {
			return this.hasSearchTerm(ref, this.searchTerm);
		},
	},
};
</script>
