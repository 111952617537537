<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		id="faqs"
		class="animated fadeIn">
		<div class="row">
			<div class="col">
				<search-input @submit="search" />
			</div>
		</div>
		<div class="row">
			<div class="col-md-4">
				<ul class="list-group mb-4">
					<template v-for="category in categories">
						<div
							v-if="canUserSeeCategory(category.name)"
							:key="category.name"
							:class="selectedCategory.name === category.name ? 'open active' : 'text-primary'"
							class="list-group-item btn text-left"
							@click="goTo(category.name, searchTerm)">
							<i
								:class="category.icon"
								class="mr-2" />
							{{ translate(category.translate_key) }}
						</div>
					</template>
				</ul>
			</div>
			<div class="col-md-8">
				<div ref="all-faq">
					<template v-for="category in categories">
						<component
							:is="category.name"
							v-show="showCategory(category.name)"
							:key="category.name"
							v-bind="{ searchTerm }" />
					</template>
				</div>
				<div
					v-if="refsLoaded && noResults"
					class="alert text-center mt-3 mb-0 py-3">
					<i class="fa fa-3x fa-exclamation-circle mb-2" />
					<h4 v-html="translate('no_search_results', { searchTerm })" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	distributor, preDistributor, admin, customerRoles, affiliate,
} from '@/settings/Roles';
import { SEARCH_CONFIG } from '@/settings/Faq';
import {
	Faq as messages,
} from '@/translations';
import BackofficeProcedure from './BackofficeProcedure';
import CommissionsWallets from './CommissionsWallets';
import CompPlan from './CompPlan';
import GeneralInformation from './GeneralInformation';
import OrdersShipping from './OrdersShipping';
import ProductInformation from './ProductInformation';
import SearchInput from './components/SearchInput';
import SearchMixin from './mixins/Search';
// import Payment from './Payment';

// this config was not included in settings/Faq.js due to it requiring the imported components as a property
const FAQ_CATEGORY_CONFIG = [
	{
		name: 'FaqBackofficeProcedure',
		url: 'backoffice-procedure',
		icon: 'fas fa-user-alt',
		component: BackofficeProcedure,
		translate_key: 'backoffice_procedure',
	},
	{
		name: 'FaqCommissionsWallets',
		url: 'commissions-wallets',
		icon: 'fas fa-user-alt',
		roles: [affiliate, distributor, ...admin],
		component: CommissionsWallets,
		translate_key: 'commissions_wallets',
	},
	{
		name: 'FaqCompPlan',
		url: 'comp-plan',
		icon: 'fas fa-user-alt',
		roles: [distributor, ...admin],
		component: CompPlan,
		translate_key: 'comp_plan',
	},
	{
		name: 'FaqGeneralInformation',
		url: 'general-information',
		icon: 'fas fa-user-alt',
		roles: [...customerRoles, preDistributor, distributor, ...admin],
		component: GeneralInformation,
		translate_key: 'general_info',
	},
	{
		name: 'FaqOrdersShipping',
		url: 'orders-shipping',
		icon: 'fas fa-user-alt',
		roles: [...customerRoles, preDistributor, distributor, ...admin],
		component: OrdersShipping,
		translate_key: 'orders_shipping',
	},
	// {
	// 	name: 'FaqPayment',
	// 	url: 'payment',
	// 	icon: 'fas fa-clipboard-list',
	// 	component: Payment,
	// 	translate_key: 'payment',
	// },
	{
		name: 'FaqProductInformation',
		url: 'product-information',
		icon: 'fas fa-user-alt',
		roles: [...customerRoles, preDistributor, distributor, ...admin],
		component: ProductInformation,
		translate_key: 'product_info',
	},
];

export default {
	name: 'Faq',
	messages,
	components: Object.assign(
		{ SearchInput },
		...FAQ_CATEGORY_CONFIG.map((category) => ({ [category.name]: category.component })),
	),
	mixins: [SearchMixin],
	data() {
		return {
			searchTerm: '',
			selectedCategory: FAQ_CATEGORY_CONFIG[0],
			defaultCategory: FAQ_CATEGORY_CONFIG[0],
			categories: FAQ_CATEGORY_CONFIG,
		};
	},
	computed: {
		noResults() {
			return this.searchTerm ? !this.hasSearchTerm('all-faq', this.searchTerm) : false;
		},
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			const loadedCategory = [...this.categories, SEARCH_CONFIG].find((category) => this.$route.path.includes(category.url));
			const searchTerm = this.$route.query[SEARCH_CONFIG.queryKey];

			if (!searchTerm) this.goTo(loadedCategory.name !== SEARCH_CONFIG.name ? loadedCategory.name : this.defaultCategory.name);

			this.selectedCategory = loadedCategory;
			this.searchTerm = searchTerm;
		},
		goTo(name, searchTerm = false) {
			const options = {};

			if (name !== this.$route.name) {
				options.name = name;
			}

			if (searchTerm) {
				options.query = { [SEARCH_CONFIG.queryKey]: searchTerm };
			} else if (Object.keys(this.$route.query).length !== 0) {
				options.query = {};
			}

			if (Object.keys(options).length === 0) return;
			this.$router.push(options).catch(() => {});
		},
		search(searchTerm) {
			this.selectedCategory = searchTerm ? SEARCH_CONFIG : this.defaultCategory;
			this.searchTerm = searchTerm;
			this.updateUrl();
		},
		updateUrl() {
			const baseUrl = window.location.origin;
			const path = `${this.$route.path.split('/')[1]}/${this.selectedCategory.url}`;
			const searchQuery = this.searchTerm ? `?${SEARCH_CONFIG.queryKey}=${this.searchTerm}` : '';

			const url = `${baseUrl}/${path}${searchQuery}`;
			window.history.replaceState({ path: url }, '', url);
		},
		canUserSeeCategory(name) {
			const { roles } = [...this.categories, SEARCH_CONFIG].find((category) => name === category.name);
			return !roles ? true : roles.includes(this.$user.details().type);
		},
		showCategory(name) {
			const showAll = this.selectedCategory.name === SEARCH_CONFIG.name;
			const showSelected = name === this.selectedCategory.name;

			return (showSelected || showAll) && this.canUserSeeCategory(name);
		},
	},
};
</script>

<style>
#faqs .when-opened,
#faqs .when-closed {
	float: right;
}

#faqs .collapsed > .when-opened,
#faqs :not(.collapsed) > .when-closed {
	display: none;
}
</style>
